<template>
  <div class="Page">
    <div class="opt-bar flex">
      <el-button @click="onToday" size="mini" v-ripple>今天</el-button>
      <el-button @click="onYestoday" size="mini" v-ripple>昨天</el-button>
      <el-input size="small"
          @keyup.enter.native="onCurrentPageChange(1)"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="onSkeyClear"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
      <el-date-picker
        size="small"
        v-model="dayLimit"
        @change="onCurrentPageChange(1)"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-button @click="onExport" size="small" v-ripple>导出</el-button>
    </div>
    <el-table :data="page.list" element-loading-text="Loading..."
      size="small"
      border fit highlight-current-row
      header-row-class-name="tableHeader"
      :height="tableHeight"
      :row-class-name="tableRowClass"
      row-key="id"
      default-expand-all
    >
      <el-table-column fixed align="center" label="Id" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column v-if="login.role=='ADMIN'" label="Plat" width="80" align="left">
        <template slot-scope="scope">
          {{ scope.row.platid }}
        </template>
      </el-table-column>
      <el-table-column label="昵称" width="130" align="left">
        <template slot-scope="scope">
          <div class="nickname">
          <el-avatar v-if="scope.row.headimgurl" :size="21" :src="scope.row.headimgurl"></el-avatar>
          <el-avatar v-else :size="21" src="@/assert/nohead.png"></el-avatar>
          <span>{{ scope.row.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="绑定手机" width="100" align="left">
        <template slot-scope="scope">
          {{ scope.row.phone}}
        </template>
      </el-table-column>
      <el-table-column label="openid" width="230" align="left">
        <template slot-scope="scope">
          {{ scope.row.openid}}
        </template>
      </el-table-column>
      <el-table-column label="关注" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.subscribe==1?'已关注':'未关注'}}
        </template>
      </el-table-column>
      <el-table-column label="关注时间" width="135" align="left">
        <template slot-scope="scope">
          {{ transSubscribeTime(scope.row.subscribe_time)}}
        </template>
      </el-table-column>
      <el-table-column label="备注"  align="left" class="hidden-md-and-down">
        <template slot-scope="scope">
          {{scope.row.note}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="right" fixed="right">
        <template slot-scope="scope">
            <icon-btn @click="handleEdit(scope.$index, scope.row)"
            icon="iconedit1"
            type="warning"
            ></icon-btn>
            <icon-btn @click="handleDelete(scope.$index, scope.row)"
            icon="icondel1"
            type="error"
            ></icon-btn>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
      <el-pagination
        @size-change="onPageSizeChange"
        @current-change="onCurrentPageChange"
        :current-page="page.currentPage"
        :page-sizes="[20, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
      <div class="pager-time" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
    </div>

  </div>
</template>

<script>
import IconBtn from '../../components/btns/IconBtn'
export default {
  components: {
    IconBtn
  },
  data() {
    return {
      login: {role: ''},
      tableHeight: 560,
      skey: '',
      dayLimit: [],
      page: {pg: 1, list:[], total: 0, pageSize: 20},
      queryTime: 0,
    };
  },
  created(opt) {
    this.login = this.getItem('login')
    this.resize();
    window.onresize = this.resize();
    this.onCurrentPageChange(1);
  },
  methods: {

    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    onToday() {

    },
    onYestoday() {

    },
    onExport() {

    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        pg : pg,
        pageSize: this.page.pageSize,
        skey: this.skey
      }
      if (this.dayLimit.length > 0) {
        console.log(this.dayLimit)
        pm.dayLimit = this.dayLimit
      }
      this.post('api/me/fans', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        this.page = res.data
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    transStat(stat) {
      var res = ''
      switch(stat) {
        case 0:
          res = '禁用'
          break;
        case 1:
          res = '正常'
          break;
      }
      return res
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    transSubscribeTime(str) {
      if (this.isEmpty(str)) {
        return ''
      }
      if (str.length >= 19) {
        return str.substring(0, 19);
        // return str.substring(0, 4) + '-' + str.substring(5, 7) + '-' + str.substring(8, 10)
        //   + ' ' + str.substring(10, 12)+':'+str.substring(14, 16) + ':' + str.substring(17,19)
      } else if (str.length == 10){
        var dt = new Date(parseInt(str) * 1000);
        return this.util.formatDate(dt)
      }
    }
  } // methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .test {
    color: $test;
  }

  .nickname {

  }
}

</style>
